import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,[_c(VCol,{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-bold"},[_vm._v(_vm._s(_vm.$t("howtos.title")))]),_c(VSpacer),_c('CreateVideo'),_c('div',{staticClass:"mx-2"}),_c(VBtn,{attrs:{"icon":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.getHowTos}},[_c(VIcon,[_vm._v("mdi-refresh")])],1)],1),_c(VCol,{attrs:{"cols":"12"}},[(_vm.videos.length > 0 && !_vm.loading)?_c(VList,_vm._l((_vm.videos),function(video,i){return _c('VideoItem',{key:i,attrs:{"video":video}})}),1):_c('div',[(_vm.loading)?_c('div',[_vm._v(_vm._s(_vm.$t("howtos.loading")))]):_vm._e(),(!_vm.videos.length)?_c('div',[_vm._v(_vm._s(_vm.$t("howtos.none")))]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }