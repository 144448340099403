

























import Vue from "vue";
import { Component } from "vue-property-decorator";

import VideoItem from "@/views/admin/components/VideoItem.vue";
import CreateVideo from "@/views/admin/components/CreateVideo.vue";
import { Action, Getter } from "vuex-class";
import { HowToVideo } from "@/core/models";

@Component({ components: { CreateVideo, VideoItem } })
export default class HowToVideos extends Vue {
  @Getter("howTos/loading") loading!: boolean;
  @Getter("howTos/retrieved") retrieved!: boolean;
  @Getter("howTos/getVideos") videos!: HowToVideo[];
  @Action("howTos/getHowTos") getHowTos!: Function;

  created() {
    if (!this.retrieved) this.getHowTos();
  }
}
